<!--
 * @Description: 样品明细
 * @Author: pcc
 * @Date: 2022-12-07 08:59:40
 * @LastEditors: zml
 * @LastEditTime: 2023-09-11 12:20:51
-->
<template>
  <div class="pro-box">
    <component :proInfo="proInfo" v-if="proInfo !== null" :is="componentName"></component>
    <div class="container empty" v-if="proInfo == null">
      <el-empty description="404，你访问的内容不存在"></el-empty>
    </div>
  </div>
</template>
<script>
import { getDetail } from '@/api/product'
import { mapGetters } from 'vuex'
import mixins from '@/utils/mixins'
import transformMark from '@/utils/water'
export default {
  name: 'proDetailPage',
  mixins: [mixins],
  components: {},
  data() {
    return {
      proInfo: {},
      componentName: ''
    }
  },
  computed: {
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    }),
    ...mapGetters('operate', {
      getEnquiry: 'get_enquiry'
    })
  },
  watch: {
    $route(to) {
      if (to.name === 'proDetail') {
        this.initData()
      }
    }
  },
  created() {
    this.initData()
    this.initDom()
  },
  methods: {
    initDom() {
      switch (this.$templates()) {
        case 'themeTemplateOne':
          this.componentName = 'pageProDetailOne'
          break
        case 'themeTemplateTow':
          this.componentName = 'pageProDetailTow'
          break
        case 'themeTemplateThree':
          this.componentName = 'pageProDetailThree'
          break
        case 'themeTemplateFour':
          this.componentName = 'pageProDetailFour'
          break
        case 'themeTemplateFive':
          this.componentName = 'pageProDetailFive'
          break
        case 'themeTemplateSix':
          this.componentName = 'pageProDetailSix'
          break
        case 'themeTemplateSeven':
          this.componentName = 'pageProDetailSeven'
          break
        default:
          this.componentName = ''
          break
      }
    },
    initData() {
      const param = {
        client_Nu: this.userInfo.client_Nu,
        co_Nu: this.$route.query.coNu
      }

      getDetail(param).then(res => {
        if (res.data) {
          // 产品列表
          res.data.imgList.forEach(item => {
            transformMark(item.filePath + '?imageMogr2/thumbnail/800x').then(
              result => {
                item.filePath = result
              }
            )
          })

          // 包装列表
          res.data.packImgList.forEach(item => {
            transformMark(item.filePath + '?imageMogr2/thumbnail/800x').then(
              result => {
                item.filePath = result
              }
            )
          })

          // 实样图列表
          res.data.realImgList.forEach(item => {
            transformMark(item.filePath + '?imageMogr2/thumbnail/800x').then(
              result => {
                item.filePath = result
              }
            )
          })
          this.proInfo = { ...res.data, _num_: false }
          transformMark(
            this.proInfo.imgUrl + '?imageMogr2/thumbnail/800x'
          ).then(result => {
            this.proInfo.imgUrl = result
          })
          this.getEnquiry[0].list.forEach(item => {
            if (item.co_Nu === res.data.co_Nu) {
              this.proInfo = { ...res.data, _num_: item._num_ }
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pro-box {
  // background-color: #f1f3f6;
  // padding: 15px 0;

  & .page-pro {
    margin-top: 15px;
  }

  & .empty {
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>