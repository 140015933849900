/*
 * @Description: 方法
 * @Author: zml
 * @Date: 2023-03-22 14:15:45
 * @LastEditors: zml
 * @LastEditTime: 2023-09-18 19:22:38
 */
import store from "../store/index"
import { setDocumentTitle } from '@/utils/domUtil'


/**
 * 设置当前模板
 */
export function templates() {
  const template = "themeTemplateSix" // 国安
  return template
}


/**
 * 传值需严格遵行下面的顺序
 * @param {Object} zhValue 中文
 * @param {Object} enValue 英文
 * @param {Object} threelanguages 第三语言
 */
export function filterLanguage(zhValue = '', enValue = '', threelanguages = '') {
  const i18n = store.state.operate.language
  let language = enValue
  switch (i18n) {
    case 'zh': // 中文
      language = zhValue
      setDocumentTitle(store.state.setting.webSetting.title)
      break;
    case 'en': // 英文
      language = enValue
      setDocumentTitle(store.state.setting.webSetting.enTitle)
      break;
    case 'ru': // 俄语
    case 'es': // 西班牙语
      language = threelanguages
      setDocumentTitle(store.state.setting.webSetting.rusTitle)
      break;
  }
  return language
}
