/*
* @Description: 赢鱼英文
* @Author: zml
* @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-09-26 09:26:03
*/
export default {
    "YY.login": "Sign in",
    "YY.loginMsg": "Access to your account & order",
    "YY.login.EmailRoMobile": "Email / Mobile",
    "YY.login.password": "Password",
    "YY.login.Record": "Remember me",
    "YY.Register.title": "Log in",
    "YY.Register.userAccount": "User Account",
    "YY.Register.Mobile": "Mobile",
    "YY.Register.Email": "Email",
    "YY.Register.Company": "Company",
    "YY.Reset.title": "Log in",




    "YY.register": "Register",
    "YY.logout": "Logout",
    "YY.MyOrder": "My Order",
    "YY.logoBox.placeholder": "Please enter the product keyword",
    "YY.logoBox.search": "Search",
    "YY.logoBox.AdvancedSearch": "Advanced Search",
    "YY.nav.Home": "Home",
    "YY.nav.Products": "PRODUCTS",
    "YY.nav.Shop": "Shop",
    "YY.nav.Service": "Service",
    "YY.nav.Contacts": "Contact",
    "YY.nav.Order": "Order",
    "YY.nav.OurServices": "Our Services",
    "YY.nav.About": "About Us",
    "YY.nav.OurMissions": "Our Missions",
    "YY.nav.Categories": "Categories",
    "YY.nav.QualityControl": "Quality Control",
    "YY.nav.SmallBusinessSupport": "S&M Business Support",
    "YY.nav.AmazonSellersSupport": "Amazon Sellers Support",
    "YY.nav.BecomeOurPartner": "Become Our Partner",
    "YY.nav.SubmitYourInquiry": "Submit Your Inquiry",



    "YY.nav.contact": "CONTACT US",
    "YY.nav.news": "NEWS",
    "YY.nav.cart": "CART",
    "YY.ViewPrice": "View Price",
    "YY.SameFactory": "Same Factory",
    "YY.newPro": "NEW ARRIVIALS",
    "YY.AnotherBatch": "Another Batch",
    "YY.ViewMore": "View More",
    "YY.hotPro": "HOT ITEMS",
    "YY.hotVidio": "HOT ITEM VODEO",
    "YY.proUnBoxVidio": "PRODUCT UNBOXING VIDEO",
    "YY.Search": "Search",
    "YY.Reset": "Reset",
    "YY.Description": "Description (Any Part Of Product Name)",
    "YY.ItemNO": "Item NO.",
    "YY.MTCode": "MT Code",
    "YY.FTYCode": "FTY Code",
    "YY.Kind": "Kind",
    "YY.Size": "Size",
    "YY.Price": "Price",
    "YY.Date": "Date",
    "YY.Sort": "Sort",
    "YY.DateDesc": "DATE DESC",
    "YY.DateAsc": "DATE ASC",
    "YY.PriceDesc": "PRICE DESC",
    "YY.PriceAsc": "PRICE ASC",
    "YY.NO.Desc": "NO. DESC",
    "YY.NO.Asc": "NO. ASC",
    "YY.PleaseEnterTheProductNameKeyword": "Please enter the product name keyword",
    "YY.PleaseSelect": "Please select",
    "YY.PleaseLength": "Length",
    "YY.PleaseWidth": "Width",
    "YY.PleaseHeight": "Height",
    "YY.PleaseMinPrice": "Min price",
    "YY.PleaseMaxPrice": "Max price",
    "YY.PleaseStartDate": "Start date",
    "YY.PleaseEndDate": "End date",
    "YY.UserAccount": "User Account",
    "YY.password": "Password",
    "YY.GROUPSEARCH": "GROUPSEARCH",
    "YY.Video_playback": "Video playing",
    "YY.onUserAccount": "Don’t have an account",
    "YY.SignUp": "Sign up",
    "YY.ForgetPassword": "Forget password?",
    "YY.resetPasswords": "Reset password",
    "YY.emailAddress": "Email address",
    "YY.verifyCode": "Verify code",
    "YY.getVerifyCode": "Get code",
    "YY.newPassword": "New password",
    "YY.confirmPassword": "Confirm password",
    "YY.Submit": "Submit",
    "YY.SignUpAccount": "Sign up",
    "YY.LoginOnly": "Login only",
    "YY.Name": "Name",
    "YY.NameMsg": "Please enter your name, 2 to 30 characters",
    "YY.passwordMsg": "At least 6 characters",
    "YY.requiredFields": "*Required fields",
    "YY.Email": "Email",
    "YY.EmailMsg": "Please enter email. Used for login",
    "YY.gender": "Gender",
    "YY.man": "Man",
    "YY.woman": "Woman",
    "YY.customerSource": "Customer source",
    "YY.salesman": "Contact Salesman",
    "YY.Country": "Country",
    "YY.companyName": "Company name",
    "YY.duty": "Job Titel",
    "YY.tel": "Tel",
    "YY.mobile": "Mobile",
    "YY.fax": "Fax",
    "YY.Yahoo": "Yahoo",
    "YY.website": "Website",
    "YY.address": "Address",
    "YY.postalCode": "Postal code",
    "YY.ExistingAccount": "Log in to ING Toys",
    "YY.DirectLogin": "Log in directly!",
    "YY.productPrice": "Price",
    "YY.ProductNumber": "Item NO.",
    "YY.Packing": "Packing",
    "YY.OuterBoxCBM": "CBM",
    "YY.OuterBoxCUFT": "CUFT",
    "YY.Innerboxes": "Inner Boxes",
    "YY.OuterQTY": "Outer QTY",
    "YY.GWAndNW": "G.W/N.W",
    "YY.productSize": "Product Size",
    "YY.packageSize": "Package Size",
    "YY.cartonSize": "Carton Size",
    "YY.addCart": "Add To Cart",
    "YY.delCart": "Cancel Purchase",
    "YY.product": "PRODUCTS",
    "YY.NewsUpdates": "News Updates",
    "YY.ReleaseDate": "Release Date",
    "YY.MyCart": "My Cart",
    "YY.ID": "ID",
    "YY.picture": "Picture",
    "YY.ArticleNo": "Item No.",
    "YY.Description": "Description",
    "YY.CartOuterQTY": "Outer QTY",
    "YY.CBM": "CBM",
    "YY.CTNS": "CTNS",
    "YY.totalQTY": "Total QTY",
    "YY.TotalVolume": "Total CBM",
    "YY.total": "Total",
    "YY.delete": "Delete",
    "YY.checkAll": "Check All",
    "YY.clear": "Clear",
    "YY.totalItems": "Total Items",
    "YY.totalCTNS": "Total CTNS",
    "YY.noData": "No Data",
    "YY.totalPrice": "Total Price",
    "YY.systemTip": "Tip",
    "YY.confirm": "Confirm",
    "YY.cancel": "Cancel",
    "YY.deleteData": "Do you want to delete the data?",
    "YY.clearCartData": "Do you want to empty your shopping cart?",
    "YY.submitTip": "Confirm to submit the order?",
    "YY.MyOrder": "My Order",
    "YY.PleaseOrderNum": "Please enter the order number",
    "YY.number": "Number",
    "YY.viewDetails": "View Details",
    "YY.orderTime": "Order Time",
    "YY.linkman": "Linkman",
    "YY.sendDate": "Delivery Date",
    "YY.expressNumber": "Tracking Number",
    "YY.orderStatus": "Order Status",
    "YY.unshipped": "Unshipped",
    "YY.shipped": "Shipped",
    "YY.SiteNavigation": "Site Navigation",
    "YY.ContactUs": "Contact us",
    "YY.FollowUs": "Follow us",
    "YY.Landline": "Landline",
    "YY.Email": "E-mail",
    "YY.Phone": "Phone",
    "YY.WeChat": "WeChat",
    "YY.Technic": "Technic Sustain",
    "YY.Software": "HongSheng Soft",
    "YY.detailBtn": "Product details",
    "YY.noDetailBtn": "No product details",
    "YY.classify": "CATALOG",
    "YY.exclusive": "EXCLUSIVE RECOMMENDATIONS",
    "YY.loading": "Loading...",
    "YY.updateTime": "UpdateTime",
    "YY.SearchMsg": "The above search criteria can be any one or more comprehensive searches",
    "YY.MessageTitle": "Submit your requirements online",
    "YY.MessageMsg": "We will reply to your email within 24 hours, excluding holidays",
    "YY.MessageName": "YOUR NAME",
    "YY.MessageTel": "YOUR PHONE",
    "YY.MessageEmail": "YOUR EMAIL",
    "YY.MessageNeeds": "YOUR NEEDS",
    "YY.SendInquiry": "Send Inquiry",
    "YY.EnquiryList": "ENQUIRY",
    "YY.enquiry": "Enquiry",
    "YY.MyEnquiryList": "My Inquiry Form",
    "YY.Remark": "Remark",
    "YY.Enquirys": "Leave your email and we'll get back to you",
    "YY.proClass": "Product Category",


    "YY.topWelcome": "Welcome to ING Toys",
    "YY.searchPro": "Search...",
    "YY.NewArrivals": "New Arrivals",
    "YY.BestSellers": "Best Sellers",
    "YY.SpecialOffers": "Special Offers",
    "YY.OurServices": "Our Services",
    "YY.OurServicesMsg": "ING Toys aims to become the most powerful toy supplier globally. Our core strengths are demonstrated in the following areas: an extensive selection of 1,000,000+ diverse products, efficient service speed to quickly meet market demands, ensuring that customers do not miss any business opportunities.",
    "YY.oemMsg": "Unlocking Your Brand's Potential with Custom ODM/OEM Services",
    "YY.oemMsg1": "Unlocking Opportunities with Low MOQ (Minimum Order Quantity) Services",
    "YY.oemMsg2": "Empowering Your Amazon Business with Supply Chain Excellence",
    "YY.SupportTitle": "Small Business Support",
    "YY.AmazonTitle": "Amazon Seller Support",
    "YY.ourMissionTitle": "OUR MISSIONS",
    "YY.ourMissionMsg": "We give our partners maximum support in optimising processes within the distribution chain in order to help them achieve a competitive edge on the market.",
    "YY.EASY_SOURCING": "EASY SOURCING",
    "YY.EASY_Msg1": "An easy way to post your sourcing,requests and quotes.",
    "YY.EASY_Msg2": "Together, let's create a world where laughter, learning, and play go hand in hand.",
    "YY.proName": "Product Name",
    "YY.searchPacking": "Packing",
    "YY.QualityControlMsg": "Quality is our promise. Each toy in our inventory is crafted to meet the highest standards of safety and durability. Rest easy knowing that you're delivering smiles that will last.",
    "YY.amazon1.title": "Multiple Private Label & Packaging Ideas",
    "YY.amazon1.msg1": "Low MOQ, tight budgets, and the difficulty of finding well-matched suppliers on Alibaba canmake it hard to develop your brand products. We're here to help you",
    "YY.amazon1.msg2": "Alter Materials, Sizes, And Colors Based On Existing Products",
    "YY.amazon1.msg3": "Develop Brand New Products According To Your 3d Designs",
    "YY.amazon1.msg4": "Customize Packaging To Stand Out From Your Competitors",
    "YY.amazon1.msg5": "Kit And Bundle Products To Generate More Sales",
    "YY.amazon2.title": "1-by-1 Quality Inspection",
    "YY.amazon2.titleMsg1": "Empowering Your Amazon Business with Supply Chain Excellence",
    "YY.amazon2.titleMsg2": "Are you an Amazon seller seeking reliable supply chain solutions to fuel your e-commerce success? Look no further! We specialize in providing top-notch Amazon Supply Chain Services designed to streamline your operations and ensure a seamless flow of inventory",
    "YY.amazon2.titleMsg3": "Why Choose Our Amazon Supply Chain Services?",


    "YY.amazon2.msg1": "As an Amazon seller, getting bad reviews because of product quality issues can be fatal. Inspection companies and suppliers can check 20–30% of your boxes but they’ll only tell you whether the result is a fail or pass.",
    "YY.amazon2.msg2": "We can inspect your items one by one for just a small labor fee. We’ll identify every defective item and request the Chinese factory to fix it.",
    "YY.amazon3.title": "Low-cost Amazon Fba Labeling",
    "YY.amazon3.msg1": "The Amazon FBA label service fee is $0.55 per unit and that of the 3rd party is $0.2–$0.3 per unit. We can add labels for $5 per hour,which is much more cost-effective. The labels include:",
    "YY.amazon3.msg2": "Fnsku Label",
    "YY.amazon3.msg3": "Shipping Label",
    "YY.amazon3.msg4": "Warning Sticker",
    "YY.amazon3.msg5": "Other Labels Amazon Requires",
    "YY.amazon4.title": "Free Storage In China For 1-2 Months",
    "YY.amazon4.msg1": "Amazon storage is expensive. And during peak season, sellers have limited storage capacity in Amazon arehouses. We offer free  storage for 1–2 months in China for your convenience.",
    "YY.amazon4.msg2": "To reduce Amazon storage fees, we can store some of your  products in our warehouse and send them to the Amazon FBA  warehouse in batches at different times when you need replenishment.",
    "YY.amazon.contact": "Partner with us today and let our Amazon Supply Chain Services become your trusted partner in success. We're dedicated to helping you streamline your operations, reduce costs, and deliver exceptional service to your Amazon customers. Contact us now to embark on a journey of supply chain excellence!”",

    "YY.partnership1.title": "Our Advantages",
    "YY.partnership1.msg1": "We understand the dynamics of your business. With competitive pricing and flexible ordering options, we're your strategic ally for success. Whether you're a retailer, educator, or event planner, we've got you covered.",
    "YY.partnership1.msg2": "Endless Variety",
    "YY.partnership1.msg3": "Quality Beyond Measure",
    "YY.partnership1.msg4": "Your One-Stop Toy Hub",
    "YY.partnership1.msg5": "Spreading Smiles",
    "YY.partnership1.msg6": "Your Partner in Success",
    "YY.partnership1.msg7": "Partner B2B Web Service",
    "YY.partnership2.title": "Partner B2B Web Service",
    "YY.partnership2.msg1": "We're your ultimate partner in play. From classic wonders to cutting-edge novelties, we've carefully curated an extensive collection that caters to all preferences.",
    "YY.partnership2.msg2": "Step into a world where every imagination is catered to. From educational wonders that spark curiosity to playful companions that offer comfort, our diverse collection promises something for every child's dream.",
    "YY.partnership2.msg3": "Web Shop",
    "YY.partnership3.title": "Multiple ways to contact us",
    "YY.partnership3.msg1": "Join us in the pursuit of making childhood memories brighter and more memorable. Let's collaborate and create a world of happiness.",
    "YY.partnership3.msg2": "Phone",
    "YY.partnership3.msg3": "Whatspp/Wechat",
    "YY.partnership3.msg4": "Email",
    "YY.inquiry.title": "Haven’t found the products you are needed in our website?",
    "YY.inquiry.msg1": "Let's build a thriving partnership where your success fuels ours. Connect with us today and embark on a journey of mutual growth, boundless fun, and extraordinary potential.",
    "YY.inquiry.form.title": "Select Your Sourcing Request",
    "YY.inquiry.FirstName": "First Name",
    "YY.inquiry.lastName": "Last Name",
    "YY.inquiry.Email": "Email",
    "YY.inquiry.Phone": "Phone(eg.+12345678)",
    "YY.inquiry.Address": "Address for shipping samples",
    "YY.inquiry.Company": "Company",
    "YY.inquiry.Postal/ZipCode": "Postal/Zip code",
    "YY.inquiry.Country": "Country",
    "YY.inquiry.BusinessType": "Business Type",
    "YY.inquiry.Remark": "Tell us what kind of products do you need",
    "YY.inquiry.Upload": "Upload attachments",
    "YY.inquiry.Submit": "Submit",

    "YY.oem.titileMsg": "Unlocking Your Brand's Potential with Custom ODM/OEM Services",
    "YY.oem.welcome": "Are you looking to elevate your brand and stand out in the market? Look no further! We specialize in offering top-tier ODM (Original Design Manufacturer) and OEM (Original Equipment Manufacturer) services that can bring your unique product ideas to life.",
    "YY.oem1.title1": "ODM Solution",
    "YY.oem1.content1": "With our ODM services, we provide innovative design solutions tailored to your specific needs, helping you create products that truly reflect your brand's identity. Our team of experts will work closely with you to develop, design, and manufacture products that exceed your expectations.",
    "YY.oem1.title2": "OEM Solution",
    "YY.oem1.content2": "If you already have a product concept in mind, our OEM services allow you to leverage our manufacturing capabilities to produce high-quality, custom-branded products. We understand the importance of maintaining your brand integrity, and our OEM services ensure that your products meet the highest standards of quality and craftsmanship.",
    "YY.oem2.title": "Why Choose Our ODM/OEM Services?",
    "YY.oem2.title1": "Customization",
    "YY.oem2.msg1": "We believe in turning your vision into reality. Our ODM/OEM services are all about customization, ensuring that your products are unique and tailored to your brand.",
    "YY.oem2.title2": "Quality Assurance",
    "YY.oem2.msg2": "We are committed to delivering products of the highest quality. Our rigorous quality control processes guarantee that every product meets the industry's standards.",
    "YY.oem2.title3": "Efficiency",
    "YY.oem2.msg3": "Time is money, and we understand the importance of timely delivery. Our streamlined processes ensure that your products are manufactured and delivered efficiently.",
    "YY.oem2.title4": "Cost-Effective Solutions",
    "YY.oem2.msg4": "We offer competitive pricing without compromising on quality. Our ODM/OEM services are designed to maximize your ROI",
    "YY.oem2.title5": "Global Reach",
    "YY.oem2.msg5": "We have a global presence, allowing us to cater to clients worldwide. No matter where you are, we can provide the services you need.",
    "YY.oem.contact": "Partner with us today and take your brand to new heights with our ODM/OEM services. Let's turn your ideas into market-leading products that captivate your audience and drive success. Contact us now to explore the endless possibilities!",

    "YY.support.titileMsg": "Unlocking Opportunities with Low MOQ (Minimum Order Quantity) Services",
    "YY.support.welcome1": "Are you a startup, small business, or entrepreneur seeking to bring your innovative product ideas to life without the burden of high order quantities?",
    "YY.support.welcome2": "Look no further! We specialize in providing Low MOQ Services that empower you to turn your concepts into reality with ease and affordability.",
    "YY.support1.title": "Why Choose Our Low MOQ Services?",
    "YY.support1.title1": "Accessibility",
    "YY.support1.msg1": "Our Low MOQ services make product development accessible to businesses of all sizes. Whether you need a small batch for testing the market or have limited storage space, we've got you covered.",
    "YY.support1.title2": "Cost-Effective",
    "YY.support1.msg2": "Say goodbye to excessive inventory costs. Our Low MOQ services allow you to minimize upfront expenses and manage your budget efficiently.",
    "YY.support1.title3": "Product Diversity",
    "YY.support1.msg3": "Explore a wide range of products without the need for large orders. We're here to help you diversify your offerings.",
    "YY.support1.title4": "Market Agility",
    "YY.support1.msg4": "Stay agile and responsive to market trends. With Low MOQ services, you can quickly adapt to changing consumer preferences and launch new products faster.",
    "YY.support1.title5": "Quality Assurance",
    "YY.support1.msg5": "We maintain the same high standards for quality and craftsmanship, regardless of order size. Your products will meet industry standards and exceed customer expectations.",
    "YY.support1.title6": "Flexible Scaling",
    "YY.support1.msg6": "As your business grows, our services can scale with you. Transition seamlessly from lo",
    "YY.support.contact": "Don't let high MOQ slimit your potential. Partner with us today, and together, we'll bring your unique product concepts to life with our Low MOQ Services. Experience the flexibility, affordability, and quality that sets us apart. Contact us now to embark on your journey of innovation and growth!",







}   
