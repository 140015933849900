/*
* @Description: 赢鱼中文
* @Author: zml
* @Date: 2023-03-23 14:52:59
 * @LastEditors: zml
 * @LastEditTime: 2023-09-22 19:20:58
*/
export default {
    "YY.login": "登录",
    "YY.loginMsg": "访问您的帐户和订单",
    "YY.login.EmailRoMobile": "邮箱 / 手机",
    "YY.login.password": "密码",
    "YY.login.Record": "记住我",
    "YY.Register.title": "注册账号",
    "YY.Register.userAccount": "用户名",
    "YY.Register.Mobile": "手机号",
    "YY.Register.Email": "电子邮箱",
    "YY.Register.Company": "公司",
    "YY.Reset.title": "重置密码",

    
    "YY.register": "注册",
    "YY.logout": "退出",
    "YY.MyOrder": "我的订单",
    "YY.logoBox.placeholder": "请输入产品关键字",
    "YY.logoBox.search": "搜索",
    "YY.logoBox.AdvancedSearch": "高级搜索",
    "YY.nav.Home": "首页",
    "YY.nav.Products": "产品",
    "YY.nav.Shop": "商铺",
    "YY.nav.Service": "服务",
    "YY.nav.Contacts": "联系",
    "YY.nav.Order": "订单",
    "YY.nav.OurServices": "我们的服务",
    "YY.nav.About": "关于我们",
    "YY.nav.OurMissions": "我们的使命",
    "YY.nav.Categories": "类别",
    "YY.nav.QualityControl": "质量控制",
    "YY.nav.SmallBusinessSupport": "S&M 商业支持",
    "YY.nav.AmazonSellersSupport": "亚马逊支持",
    "YY.nav.BecomeOurPartner": "成为我们的伙伴",
    "YY.nav.SubmitYourInquiry": "提交您的需求",


    "YY.nav.contact": "联系我们",
    "YY.nav.news": "新闻",
    "YY.nav.cart": "购物车",
    "YY.ViewPrice": "查看价格",
    "YY.SameFactory": "同一个工厂",
    "YY.newPro": "最新产品",
    "YY.AnotherBatch": "换一批",
    "YY.ViewMore": "查看更多",
    "YY.hotPro": "热销产品",
    "YY.hotVidio": "热点视频",
    "YY.proUnBoxVidio": "开箱视频",
    "YY.Search": "搜索",
    "YY.Reset": "重置",
    "YY.Description": "产品名称 （ 产品名称的任何部分 ）",
    "YY.ItemNO": "项目编号",
    "YY.MTCode": "公司编号",
    "YY.FTYCode": "出厂货号",
    "YY.Kind": "种类",
    "YY.Size": "产品规格",
    "YY.Price": "价格",
    "YY.Date": "日期",
    "YY.Sort": "排序",
    "YY.DateDesc": "日期降序",
    "YY.DateAsc": "日期升序",
    "YY.PriceDesc": "价格降序",
    "YY.PriceAsc": "价格升序",
    "YY.NO.Desc": "公司编号降序",
    "YY.NO.Asc": "公司编号升序",
    "YY.PleaseEnterTheProductNameKeyword": "请输入产品名称关键字",
    "YY.PleaseSelect": "请选择",
    "YY.PleaseLength": "长",
    "YY.PleaseWidth": "宽",
    "YY.PleaseHeight": "高",
    "YY.PleaseMinPrice": "最小价格",
    "YY.PleaseMaxPrice": "最大价格",
    "YY.PleaseStartDate": "开始日期",
    "YY.PleaseEndDate": "结束日期",
    "YY.UserAccount": "用户名",
    "YY.password": "密码",
    "YY.GROUPSEARCH": "综合搜索",
    "YY.Video_playback": "视频播放",
    "YY.onUserAccount": "没有账号",
    "YY.SignUp": "立即注册",
    "YY.ForgetPassword": "忘记密码？",
    "YY.resetPasswords": "重置密码",
    "YY.emailAddress": "电子邮箱",
    "YY.verifyCode": "验证码",
    "YY.getVerifyCode": "获取验证码",
    "YY.newPassword": "新密码",
    "YY.confirmPassword": "确认密码",
    "YY.Submit": "提交",
    "YY.SignUpAccount": "注册账号",
    "YY.LoginOnly": "仅用于登录",
    "YY.Name": "姓名",
    "YY.NameMsg": "请输入您的姓名，2~30个字符",
    "YY.passwordMsg": "至少6个字符",
    "YY.requiredFields": "*必填项",
    "YY.Email": "邮箱",
    "YY.EmailMsg": "用于登录，注册后我们将向此邮箱发送邮件以验证有效性",
    "YY.gender": "性别",
    "YY.man": "男",
    "YY.woman": "女",
    "YY.customerSource": "客户来源",
    "YY.salesman": "销售员",
    "YY.Country": "国家",
    "YY.companyName": "公司名称",
    "YY.duty": "职务",
    "YY.tel": "电话",
    "YY.mobile": "手机号",
    "YY.fax": "传真",
    "YY.Yahoo": "雅虎",
    "YY.website": "网站",
    "YY.address": "地址",
    "YY.postalCode": "邮政编码",
    "YY.ExistingAccount": "已有账号",
    "YY.DirectLogin": "直接登录！",
    "YY.productPrice": "产品价格",
    "YY.ProductNumber": "产品编号",
    "YY.Packing": "包装方式",
    "YY.OuterBoxCBM": "外箱体积",
    "YY.OuterBoxCUFT": "外箱材积",
    "YY.Innerboxes": "内盒个数",
    "YY.OuterQTY": "外箱装量",
    "YY.GWAndNW": "毛重 / 净重",
    "YY.productSize": "产品规格",
    "YY.packageSize": "包装规格",
    "YY.cartonSize": "外箱规格",
    "YY.addCart": "添加购物车",
    "YY.delCart": "取消加购",
    "YY.product": "产品",
    "YY.NewsUpdates": "新闻动态",
    "YY.ReleaseDate": "发布日期",
    "YY.MyCart": "我的购物车",
    "YY.ID": "ID",
    "YY.picture": "图片",
    "YY.ArticleNo": "货号",
    "YY.Description": "名称",
    "YY.CartOuterQTY": "外箱装量",
    "YY.CBM": "体积",
    "YY.CTNS": "箱数",
    "YY.totalQTY": "总数量",
    "YY.TotalVolume": "总体积",
    "YY.total": "合计",
    "YY.delete": "删除",
    "YY.checkAll": "全选",
    "YY.clear": "清空",
    "YY.totalItems": "总款数",
    "YY.totalCTNS": "总箱量",
    "YY.noData": "暂无数据",
    "YY.totalPrice": "总价",
    "YY.systemTip": "系统提示",
    "YY.confirm": "确定",
    "YY.cancel": "取消",
    "YY.deleteData": "是否删除该条数据？",
    "YY.clearCartData": "是否清空全部购物车？",
    "YY.submitTip": "确认提交订单？",
    "YY.MyOrder": "我的订单",
    "YY.PleaseOrderNum": "请输入订单号",
    "YY.number": "单号",
    "YY.viewDetails": "查看详情",
    "YY.orderTime": "订单时间",
    "YY.linkman": "联系人",
    "YY.sendDate": "发货时间",
    "YY.expressNumber": "快递单号",
    "YY.orderStatus": "订单状态",
    "YY.unshipped": "未发货",
    "YY.shipped": "已发货",
    "YY.SiteNavigation": "网站导航",
    "YY.ContactUs": "联系我们",
    "YY.FollowUs": "关注我们",
    "YY.Landline": "座机",
    "YY.Email": "邮箱",
    "YY.Phone": "手机号",
    "YY.WeChat": "微信号",
    "YY.Technic": "技术支持",
    "YY.Software": "深圳宏升软件技术开发有限公司",
    "YY.detailBtn": "产品详情",
    "YY.noDetailBtn": "暂无产品详情",
    "YY.classify": "产品分类",
    "YY.exclusive": "专属推荐",
    "YY.loading": "加载中...",
    "YY.updateTime": "更新时间",
    "YY.SearchMsg": "以上搜索条件可选任意一项或多项综合搜索",
    "YY.MessageTitle": "在线提交您的需求",
    "YY.MessageMsg": "我们将在24小时内回复您的电子邮件，节假日除外",
    "YY.MessageName": "你的名字",
    "YY.MessageTel": "你的手机",
    "YY.MessageEmail": "你的邮箱",
    "YY.MessageNeeds": "你的需求",
    "YY.SendInquiry": "发询价单",
    "YY.EnquiryList": "询价单",
    "YY.enquiry": "询价",
    "YY.MyEnquiryList": "我的询价单",
    "YY.Remark": "备注",
    "YY.Enquirys": "请留下您的电子邮件，我们会与您联系",
    "YY.proClass": "产品分类",

    
    "YY.topWelcome": "欢迎光临ING玩具",
    "YY.searchPro": "搜索...",
    "YY.NewArrivals": "最新产品",
    "YY.BestSellers": "热卖产品",
    "YY.SpecialOffers": "优惠产品",
    "YY.OurServices": "我们的服务",
    "YY.OurServicesMsg": "ING玩具的目标是成为全球最强大的玩具供应商。我们的核心优势体现在以下几个方面：1000000多种多样的产品可供选择，高效的服务速度可快速满足市场需求，确保客户不会错过任何商机。",
    "YY.oemMsg": "通过定制ODM/OEM服务释放品牌潜力",
    "YY.oemMsg1": "通过低最低起订量（MOQ）服务解锁机会",
    "YY.oemMsg2": "通过卓越的供应链为您的亚马逊业务赋能",
    "YY.SupportTitle": "商业支持",
    "YY.AmazonTitle": "亚马逊支持",
    "YY.ourMissionTitle": "我们的使命",
    "YY.ourMissionMsg": "我们在优化分销链流程方面为合作伙伴提供最大支持，以帮助他们在市场上获得竞争优势。",
    "YY.EASY_SOURCING": "轻松采购",
    "YY.EASY_Msg1": "发布采购、请求和报价的简单方法。",
    "YY.EASY_Msg2": "让我们一起创造一个欢笑、学习和玩耍齐头并进的世界。",
    "YY.proName": "产品名称",
    "YY.searchPacking": "包装",
    "YY.QualityControlMsg": "质量是我们的承诺。我们库存的每一款玩具都经过精心制作，符合最高的安全和耐用性标准。放心吧，知道你的笑容会持续很久。",
    "YY.amazon1.title": "多种自有品牌和包装理念",
    "YY.amazon1.msg1": "起订量低，预算紧张，在阿里巴巴上很难找到匹配的供应商，这些都会让你很难开发出品牌产品。我们是来帮你的",
    "YY.amazon1.msg2": "根据现有产品更改材质、尺寸和颜色",
    "YY.amazon1.msg3": "根据您的三维设计开发全新产品",
    "YY.amazon1.msg4": "定制包装，从竞争对手中脱颖而出",
    "YY.amazon1.msg5": "套件和捆绑产品以创造更多销售额",
    "YY.amazon2.title": "一对一质量检查",
    "YY.amazon2.titleMsg1": "通过卓越的供应链为您的亚马逊业务赋能",
    "YY.amazon2.titleMsg2": "您是否是亚马逊卖家，正在寻求可靠的供应链解决方案来推动您的电子商务成功？别再看了！我们专门提供一流的亚马逊供应链服务，旨在简化您的运营并确保库存的无缝流动",
    "YY.amazon2.titleMsg3": "为什么选择我们的亚马逊供应链服务？",
    
    "YY.amazon2.msg1": "作为亚马逊卖家，因为产品质量问题而获得差评可能是致命的。检查公司和供应商可以检查你的20-30%的箱子，但他们只会告诉你结果是失败还是通过。",
    "YY.amazon2.msg2": "我们只需支付少量的人工费就可以逐一检查您的物品。我们将识别每一个有缺陷的项目，并要求中国工厂进行修复。",
    "YY.amazon3.title": "低成本亚马逊Fba标签",
    "YY.amazon3.msg1": "亚马逊FBA标签服务费为每台0.55美元，第三方标签服务费则为每台0.2-0.3美元。我们可以以每小时5美元的价格添加标签，这更具成本效益。标签包括：",
    "YY.amazon3.msg2": "芬兰语标签",
    "YY.amazon3.msg3": "运输标签",
    "YY.amazon3.msg4": "警告标签",
    "YY.amazon3.msg5": "亚马逊要求的其他标签",
    "YY.amazon4.title": "在中国免费存储1-2个月",
    "YY.amazon4.msg1": "亚马逊存储非常昂贵。在旺季，卖家在亚马逊仓库的存储能力有限。为了您的方便，我们在中国提供1-2个月的免费存储。",
    "YY.amazon4.msg2": "为了降低亚马逊的存储费用，我们可以将您的一些产品存储在我们的仓库中，并在您需要补货时将其分批发送到亚马逊FBA仓库。",
    "YY.amazon.contact": "今天就与我们合作，让我们的亚马逊供应链服务成为您值得信赖的成功合作伙伴。我们致力于帮助您简化运营，降低成本，并为您的亚马逊客户提供卓越的服务。立即联系我们，开启卓越供应链之旅！”",

    "YY.partnership1.title": "我们的优势",
    "YY.partnership1.msg1": "我们了解您的业务动态。凭借极具竞争力的定价和灵活的订购选项，我们是您成功的战略盟友。无论您是零售商、教育工作者还是活动策划人，我们都为您提供保障。",
    "YY.partnership1.msg2": "无尽的变化",
    "YY.partnership1.msg3": "无法衡量的质量",
    "YY.partnership1.msg4": "你的一站式玩具中心",
    "YY.partnership1.msg5": "传播微笑",
    "YY.partnership1.msg6": "您的成功伙伴",
    "YY.partnership1.msg7": "合作伙伴B2B Web服务",
    "YY.partnership2.title": "合作伙伴B2B Web服务",
    "YY.partnership2.msg1": "我们是你的终极合作伙伴。从经典奇观到尖端小说，我们精心策划了一个广泛的系列，满足所有人的喜好。",
    "YY.partnership2.msg2": "走进一个满足每一种想象力的世界。从激发好奇心的教育奇迹到提供舒适感的有趣伙伴，我们的多样化系列为每个孩子的梦想做出了承诺。",
    "YY.partnership2.msg3": "网上商店",
    "YY.partnership3.title": "多种联系方式",
    "YY.partnership3.msg1": "加入我们的行列，让童年记忆更加明亮和难忘。让我们合作，创造一个幸福的世界。",
    "YY.partnership3.msg2": "手机",
    "YY.partnership3.msg3": "Whatspp/微信",
    "YY.partnership3.msg4": "电子邮件",
    "YY.inquiry.title": "在我们的网站上没有找到你需要的产品吗？",
    "YY.inquiry.msg1": "让我们建立一个蓬勃发展的合作伙伴关系，你的成功为我们的成功加油。今天就与我们联系，踏上一段相互成长、无限乐趣和非凡潜力的旅程。",
    "YY.inquiry.form.title": "选择您的采购请求",
    "YY.inquiry.FirstName": "名字",
    "YY.inquiry.lastName": "姓氏",
    "YY.inquiry.Email": "电子邮箱",
    "YY.inquiry.Phone": "手机号(eg.+12345678)",
    "YY.inquiry.Address": "装运样品的地址",
    "YY.inquiry.Company": "公司",
    "YY.inquiry.Postal/ZipCode": "邮政编码/邮编",
    "YY.inquiry.Country": "国家",
    "YY.inquiry.BusinessType": "业务类型",
    "YY.inquiry.Remark": "告诉我们你需要什么样的产品",
    "YY.inquiry.Upload": "上传附件",
    "YY.inquiry.Submit": "提交",

    "YY.oem.titileMsg": "通过定制ODM/OEM服务释放品牌潜力",
    "YY.oem.welcome": "你想提升自己的品牌并在市场上脱颖而出吗？别再看了！我们专门提供顶级ODM（原始设计制造商）和OEM（原始设备制造商）服务，可以将您独特的产品理念付诸实践。",
    "YY.oem1.title1": "ODM 解决方案",
    "YY.oem1.content1": "通过我们的ODM服务，我们为您提供量身定制的创新设计解决方案，帮助您创造真正反映您品牌身份的产品。我们的专家团队将与您密切合作，开发、设计和制造超出您预期的产品。",
    "YY.oem1.title2": "OEM 解决方案",
    "YY.oem1.content2": "如果您已经有了产品概念，我们的OEM服务可以让您利用我们的制造能力生产高质量的定制品牌产品。我们理解保持品牌完整性的重要性，我们的OEM服务确保您的产品符合最高的质量和工艺标准。",
    "YY.oem2.title": "为什么选择我们的ODM/OEM服务？",
    "YY.oem2.title1": "定制",
    "YY.oem2.msg1": "我们相信将您的愿景变为现实。我们的ODM/OEM服务都是关于定制的，确保您的产品是独一无二的，并根据您的品牌量身定制。",
    "YY.oem2.title2": "质量保证",
    "YY.oem2.msg2": "我们致力于提供最高质量的产品。我们严格的质量控制流程确保每一款产品都符合行业标准。",
    "YY.oem2.title3": "效率",
    "YY.oem2.msg3": "时间就是金钱，我们明白及时交货的重要性。我们精简的流程确保您的产品能够高效生产和交付。",
    "YY.oem2.title4": "经济高效的解决方案",
    "YY.oem2.msg4": "我们在不影响质量的情况下提供有竞争力的价格。我们的ODM/OEM服务旨在最大限度地提高您的投资回报率",
    "YY.oem2.title5": "全球影响力",
    "YY.oem2.msg5": "我们的业务遍及全球，能够满足全球客户的需求。无论您身在何处，我们都能为您提供所需的服务。",
    "YY.oem.contact": "今天就与我们合作，通过我们的ODM/OEM服务将您的品牌提升到新的高度。让我们将您的想法转化为市场领先的产品，吸引您的受众并推动成功。立即联系我们，探索无尽的可能性！",

    
    "YY.support.titileMsg": "通过低最低起订量（MOQ）服务解锁机会",
    "YY.support.welcome1": "你是一家初创企业、小企业还是企业家，希望将你的创新产品理念付诸实践，而不必承担高订单量的负担？",
    "YY.support.welcome2": "别再看了！我们专门提供最低起订量服务，使您能够轻松、经济地将概念变为现实。",
    "YY.support1.title": "为什么选择我们的最低起订量服务？",
    "YY.support1.title1": "可访问性",
    "YY.support1.msg1": "我们的低起订量服务使各种规模的企业都可以进行产品开发。无论您是需要一小批产品来测试市场，还是存储空间有限，我们都会为您提供服务。",
    "YY.support1.title2": "成本效益",
    "YY.support1.msg2": "告别过高的库存成本。我们的最低起订量服务使您能够最大限度地减少前期费用并有效管理预算。",
    "YY.support1.title3": "产品多样性",
    "YY.support1.msg3": "在不需要大量订单的情况下探索广泛的产品。我们在这里帮助您实现产品多样化。",
    "YY.support1.title4": "市场灵活性",
    "YY.support1.msg4": "保持灵活性并对市场趋势作出反应。通过低起订量服务，您可以快速适应不断变化的消费者偏好，并更快地推出新产品。",
    "YY.support1.title5": "质量保证",
    "YY.support1.msg5": "无论订单大小，我们都保持着同样的高质量和工艺标准。您的产品将达到行业标准，并超出客户的期望。",
    "YY.support1.title6": "灵活缩放",
    "YY.support1.msg6": "随着您业务的发展，我们的服务可以与您一起扩展。从lo无缝过渡",
    "YY.support.contact": "不要让高起订量降低你的潜力。今天就与我们合作，我们将通过我们的低起订量服务，让您独特的产品理念栩栩如生。体验让我们与众不同的灵活性、可负担性和质量。立即联系我们，开启您的创新和成长之旅！",







}
